
#component-modal {
    /* background: rgba(0, 0, 0, 0.3); */
    transition: 0.3s all;
    position: absolute;
    overflow: hidden;
    /* opacity: 0; */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 60;
}

#component-modal.hide {
    opacity: 0;
    z-index: 0;
}

#component-modal button.close {
    background: none;
    border: 0;
    background-image: url("../assets/close.svg");
    cursor: pointer;
    outline: 0;
    display: block;
    height: 40px;
    right: 8.2%;
    position: absolute;
    top: 5.5%;
    width: 40px;
    z-index: 10;
}

#component-modal video {
    box-shadow: 6px 6px 0px 0px rgba(0, 0, 0, 0.3);
    position: absolute;
    height: 40%;
    z-index: 0;
}

#component-modal video.head {
    right: 10%;
    top: 8%;
}

#component-modal video.component {
    right: 10%;
    top: 52%;
}

/* #component-modal video::-webkit-media-controls-fullscreen-button
{
    display: none !important;
} */
