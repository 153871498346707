html,
body,
#root {
    height: 100%;
    min-height: 100%;
}
body {
    background: url('./assets/bg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    overflow: hidden;
}

#inRatioWrapper {
    height: auto;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

img.curve {
    bottom: 0;
    left: 0;
    position: absolute;
    width: 90%;
}

img.logo {
    position: absolute;
    left: 45px;
    bottom: 4%;
    background-repeat: no-repeat;
    background-size: contain;
    opacity: 1;
    z-index: 10;
    width: 21%;
}

div.title-copy {
    left: 45px;
    top: 25px;
    opacity: 1;
    position: absolute;
    width: 450px;
    z-index: 10;
}

    div.title-copy > * {
        color: #003087;
    }

    div.title-copy > h1 {
        margin: 0;
        font-weight: bold;
        font-size: 33px;
        padding: 0;
    }
    div.title-copy > p {
        font-size: 18px;
        margin: 0;
        padding: 0;
    }

div.footer-copy {
    z-index: 10;
    position: absolute;
    opacity: 1;
    right: 45px;
    bottom: 4%;
}
    div.footer-copy h2 {
        color: #003087;
        font-size: 30px;
        line-height: 1em;
        margin: 0;
        padding: 0;
    }

.spinner {
    height: 100 #003087;
    text-align: center;
}
.spinner > div {
    width: 26%;
    position: absolute;
    left: 0;
    top: 10%;
    bottom: 0;
    right: 0;
    margin: auto;
}
.spinner img {
    width: 100%;
}
.spinner h2 {
    margin: 0;
}

.rotator {
    position: absolute;
    transform: translateZ(0px);
    display: flex;
    align-items: center;
    height: 100%;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
}

.rotator > img {
    touch-action: none;
    position: relative !important;
}

.rotator > .rotator-frame {
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: contain;
    bottom: 0;
    display: none;
    height: 100%;
    position: absolute;
    top: 0;
    touch-action: none;
    width: 100%;
}

.rotator > .rotator-frame.active {
    display: block;
}

#click-disabler {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -10;
}

    #click-disabler.active {
        z-index: 50;
    }

#hotspots {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 20;
    position: absolute;
    pointer-events: none;
}

#hotspots > div.spot {
    z-index: -10;
    transition: 0.3s all;
    opacity: 0;
    position: absolute;
    overflow: visible;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

#hotspots > div.spot.active {
    opacity: 1;
    z-index: 20;
    pointer-events: all;
}

#hotspots .spot {
    position: absolute;
    background-image: url("./assets/ring.svg");
    width: 200px;
    height: 200px;
    z-index: 50;
    background-color: rgba(8, 50, 130, 0);
    border-radius: 50%;
    border: none;
    overflow: hidden;
    cursor: pointer;
    mix-blend-mode: hard-light;
}

    #hotspots .spot .dot {
        background-color: #fff;
        border-radius: 50%;
        position: absolute;
        height: 10%;
        width: 10%;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        margin: auto;
        z-index: 10;
    }

    #hotspots .spot .pulse {
        background-color: #0066ff;
        border: 4px solid #fff;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        z-index: -1;
        border-radius: 50%;
        animation: pulse-ring 1.75s cubic-bezier(0.415, 0.71, 0.655, 1) infinite;
    }

.content-wrap {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
}

    .content-wrap > ul.menus {
        position: absolute;
        margin: 70px 0 0;
        padding: 0;
        top: 0;
    }

    .content-wrap > ul.menus span {
        content: ' ';
        background-image: url('./assets/dotted-line.svg') !important;
        background-position: 50% 50%;
        background-repeat: repeat-x;
        background-size: contain;
        height: 8px;
        margin-left: 100px;
        position: fixed;
        width: 35%;
    }

    .content-wrap > ul.menus, .content-wrap > div {
        opacity: 0;
        list-style-type: none;
        transition: all 0.5s ease-in;
        z-index: -10;
    }

    .content-wrap > ul.menus.active {
        opacity: 1;
        z-index: 10;
    }

    .content-wrap > ul.menus > li {
        background-color: #003087;
        color: #fff;
        font-weight: bold;
        font-size: 18px;
        line-height: 55px !important;
        padding-left: 20px;
        height: 55px;
        cursor: pointer;
        transition: 0.3s ease;
        left: 0;
        position: relative;
        width: 240px;
    }

    .content-wrap > ul.menus.active > li {
        pointer-events: all;
    }

    .content-wrap > ul.menus li::before {
        content: ' ';
        background-image: url("./assets/arrow-right.svg") !important;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: contain;
        margin: 0;
        padding: 0;
        width: 12px;
        height: 100%;
        position: absolute;
        right: 20px;
    }

    .content-wrap > ul.menus > li:nth-child(n + 2) {
        border-top: 2px solid #fff !important;
    }

    .content-wrap > div.component-text {
        z-index: 40;
        position: absolute;
        opacity: 1;
        left: 45px;
        top: 17%;
        width: 320px;
        height: 340px;
        padding-right: 30px;
        overflow-y: auto;
        pointer-events: all;
        overflow-x: hidden;
    }
        .content-wrap > div.component-text h3 {
            color: #003087;
            font-size: 22px;
            font-weight: bold;
            margin-bottom: 0.3em;
        }
        .content-wrap > div.component-text p {
            line-height: 1.3em;
            margin-bottom: 0.5em;
        }
        .content-wrap > div.component-text ul {
            margin: 0.5em 0;
        }
        .content-wrap > div.component-text p, .content-wrap > div.component-text ul {
            color: #000;
            font-size: 14px;
        }

    .content-wrap > div.link-wrapper {
        /* border-bottom: 1px solid #ccc; */
        z-index: 10;
        padding-bottom: 10px;
        position: absolute;
        opacity: 1;
        right: 45px;
        bottom: 12%;
    }

        .content-wrap > div.link-wrapper > p {
            font-size: 14px;
            font-weight: bold;
            margin: 0 0 0.5em 0;
            text-align: right;
        }

        .content-wrap > div.link-wrapper > p img {
            margin-left: 10px;
            width: 15px;
        }

        .content-wrap > div.link-wrapper > p a {
            color: #003087;
            text-decoration: none;
            pointer-events: all;
        }


.rotate-icon{
    width: 9%;
    position: absolute;
    bottom: 0;
    top: 13%;
    right: 23%;
    opacity: 0;
    transition: 1s opacity ease;
}
.fade{
    opacity: 1;
    animation: fade;
    animation-iteration-count: 3;
    animation-duration: 2s;
    animation-delay: 1s;
}

@keyframes fade {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}


@keyframes pulse-ring {
    0% {
        transform: scale(0.53);
    }
    80%,
    100% {
        opacity: 0;
    }
}




@media (max-width: 1400px) {
    div.title-copy > h1 {
        font-size: 28px;
    }
    div.title-copy > p {
        font-size: 16px;
    }
    .content-wrap > img.logo {
        width: 250px;
    }
    .content-wrap > div.component-text {
        width: 260px;
    }
}

@media (max-width: 1300px) {
    .content-wrap > div.component-text {
        width: 260px;
    }
}
@media (max-width: 1200px) {
    .content-wrap > div.component-text {
        width: 240px;
    }
}
@media (max-width: 1100px) {
    .content-wrap > div.component-text {
        width: 220px;
    }
}
@media (max-width: 1000px) {
    .content-wrap > div.component-text {
        width: 200px;
    }
}


.no-portrait {
    display: none;
}

/* Orientation */
.orient_overlay, .too_small_overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #fff;
    z-index: 99;
    display:none;
}

.too_small_overlay {
    z-index: 98;
}

.orient_overlay > div, .too_small_overlay > div {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    text-align: center;
}

.orient_overlay img {
    width: 30%;
}

.orient_overlay h3, .too_small_overlay h3, .orient_overlay p, .too_small_overlay p {
    color: #003087;
}

.orient_overlay h3, .too_small_overlay h3 {
    font-size: 200%;
    font-weight: bold;
}

.too_small_overlay h3 {
    padding: 0 15%;
}

@media (max-width: 1024px) {
    .too_small_overlay {
        display: block;
    }
}

@media (max-width: 1200px) {
    @media screen and (orientation:portrait) {
        .orient_overlay{
            display: block;
        }
    }
    @media screen and (orientation:landscape) {
        .orient_overlay{
            display: none;
        }
    }
}

/***** some extra buttons ****/
div.title-copy h1, div.footer-copy h2, img.logo {
    cursor: pointer;
}
